import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './styles/Brands.css';


const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [brandImage, setBrandImage] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);

  const brandsCollectionRef = collection(db, 'brands');
  const PAGE_SIZE = 20;

  const fetchBrands = async () => {
    setLoading(true);
    const q = query(brandsCollectionRef, orderBy('name'), limit(PAGE_SIZE));
    const data = await getDocs(q);
    const lastVisible = data.docs[data.docs.length - 1];
    setLastDoc(lastVisible);
    setBrands(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setLoading(false);
  };

  const fetchMoreBrands = async () => {
    setLoading(true);
    const q = query(brandsCollectionRef, orderBy('name'), startAfter(lastDoc), limit(PAGE_SIZE));
    const data = await getDocs(q);
    const lastVisible = data.docs[data.docs.length - 1];
    setLastDoc(lastVisible);
    setBrands((prev) => [...prev, ...data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))]);
    setLoading(false);
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  const handleCreateBrand = async () => {
    if (!brandName || !brandImage) return;

    const imageRef = ref(storage, `brands/${brandImage.name}`);
    await uploadBytes(imageRef, brandImage);
    const imageUrl = await getDownloadURL(imageRef);

    await addDoc(brandsCollectionRef, {
      name: brandName,
      imageUrl: imageUrl,
    });

    setIsPopupOpen(false);
    setBrandName('');
    setBrandImage(null);
    fetchBrands();
  };

  const handleDeleteBrand = async (id) => {
    const brandDoc = doc(db, 'brands', id);
    await deleteDoc(brandDoc);
    fetchBrands();
  };

  const handleEditBrand = async (id, updatedName) => {
    const brandDoc = doc(db, 'brands', id);
    await updateDoc(brandDoc, { name: updatedName });
    fetchBrands();
  };

  return (
    <div className="brands">
      <h1>Brands</h1>
      <button className="create-brand-button" onClick={() => setIsPopupOpen(true)}>
        Create Brand
      </button>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h2>Create Brand</h2>
            <input
              type="text"
              placeholder="Brand Name"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              className="input-field"
            />
            <input
              type="file"
              onChange={(e) => setBrandImage(e.target.files[0])}
              className="input-file"
            />
            <div className="popup-actions">
              <button className="btn" onClick={handleCreateBrand}>Create</button>
              <button className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <table className="brands-table">
        <thead>
          <tr>
            <th>Brand Name</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {brands.map((brand) => (
            <tr key={brand.id}>
              <td>{brand.name}</td>
              <td>
                <img src={brand.imageUrl} alt={brand.name} className="brand-image" />
              </td>
              <td>
                <button className="btn btn-edit" onClick={() => handleEditBrand(brand.id, prompt('Enter new name', brand.name))}>Edit</button>
                <button className="btn btn-delete" onClick={() => handleDeleteBrand(brand.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {lastDoc && (
        <button className="load-more-button" onClick={fetchMoreBrands} disabled={loading}>
          {loading ? 'Loading...' : 'Load More'}
        </button>
      )}
    </div>
  );
};

export default Brands;
