import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './styles/Home.css';
import bannerImage from './assets/super-search.webp';

const Home = () => {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedVariant, setSelectedVariant] = useState('');

  useEffect(() => {
    const fetchBrands = async () => {
      const brandsCollection = collection(db, 'brands');
      const brandsSnapshot = await getDocs(brandsCollection);
      setBrands(brandsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      const fetchModels = async () => {
        const modelsCollection = collection(db, 'phones');
        const q = query(modelsCollection, where('brand', '==', selectedBrand));
        const modelsSnapshot = await getDocs(q);
        setModels(modelsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      };

      fetchModels();
    } else {
      setModels([]);
      setVariants([]);
      setSelectedModel('');
      setSelectedVariant('');
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (selectedModel) {
      const fetchVariants = async () => {
        const variantsCollection = collection(db, `phones/${selectedModel}/variants`);
        const variantsSnapshot = await getDocs(variantsCollection);
        setVariants(variantsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      };

      fetchVariants();
    } else {
      setVariants([]);
      setSelectedVariant('');
    }
  }, [selectedModel]);

  const handleFixClick = () => {
    alert(`Fixing ${selectedBrand} ${selectedModel} ${selectedVariant}`);
  };

  return (
    <div>
      <Header />
      <div
        className="banner"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white',
          textAlign: 'center',
          padding: '60px 20px',
          height: '50vh',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="banner-overlay">
          <div className="banner-content">
            <h2>We repair all types of devices in just 3 steps!</h2>
            <p>We repair your devices in our stores in Madrid, or pick up anywhere in Spain.</p>
          </div>
        </div>

        <div className="dropdowns-horizontal">
          <select onChange={e => setSelectedBrand(e.target.value)} value={selectedBrand}>
            <option value="">Select Brand</option>
            {brands.map(brand => (
              <option key={brand.id} value={brand.id}>{brand.name}</option>
            ))}
          </select>

          {selectedBrand && (
            <select onChange={e => setSelectedModel(e.target.value)} value={selectedModel}>
              <option value="">Select Model</option>
              {models.map(model => (
                <option key={model.id} value={model.id}>
                  {model.model}
                </option>
              ))}
            </select>
          )}

          {selectedModel && variants.length > 0 && (
            <select onChange={e => setSelectedVariant(e.target.value)} value={selectedVariant}>
              <option value="">Select Variant</option>
              {variants.map(variant => (
                <option key={variant.id} value={variant.id}>
                  {variant.name}
                </option>
              ))}
            </select>
          )}

          <button
            onClick={handleFixClick}
            disabled={!selectedModel || (variants.length > 0 && !selectedVariant)}
            className="fix-button"
          >
            Fix
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
