// src/components/Admin/Phones.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import './styles/Phones.css';

const Phones = () => {
  const [brands, setBrands] = useState([]);
  const [series, setSeries] = useState([]);
  const [phones, setPhones] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedSeries, setSelectedSeries] = useState('');
  const [modelName, setModelName] = useState('');
  const [phoneImage, setPhoneImage] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrands = async () => {
      const brandsCollectionRef = collection(db, 'brands');
      const data = await getDocs(brandsCollectionRef);
      setBrands(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchSeries = async () => {
      if (selectedBrand) {
        const seriesCollectionRef = collection(db, `brands/${selectedBrand}/series`);
        const data = await getDocs(seriesCollectionRef);
        setSeries(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      }
    };

    fetchSeries();
  }, [selectedBrand]);

  useEffect(() => {
    const fetchPhones = async () => {
      const phonesCollectionRef = collection(db, 'phones');
      const data = await getDocs(phonesCollectionRef);
      setPhones(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchPhones();
  }, []);

  const handleCreatePhone = async () => {
    if (!selectedBrand || !modelName || !phoneImage) return;

    const imageRef = ref(storage, `phones/${phoneImage.name}`);
    await uploadBytes(imageRef, phoneImage);
    const imageUrl = await getDownloadURL(imageRef);

    const phonesCollectionRef = collection(db, 'phones');
    await addDoc(phonesCollectionRef, {
      brand: selectedBrand,
      series: selectedSeries || null,
      model: modelName,
      imageUrl,
    });

    const data = await getDocs(phonesCollectionRef);
    setPhones(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

    setSelectedBrand('');
    setSelectedSeries('');
    setModelName('');
    setPhoneImage(null);
    setIsPopupOpen(false);
  };

  const handleDeletePhone = async (id) => {
    const variantsCollectionRef = collection(db, `phones/${id}/variants`);
    const variantsSnapshot = await getDocs(variantsCollectionRef);

    if (variantsSnapshot.size === 0) {
      await deleteDoc(doc(db, 'phones', id));
      setPhones(phones.filter((phone) => phone.id !== id));
    } else {
      alert("You can't delete this phone because it has associated variants.");
    }
  };

  const getBrandNameById = (brandId) => {
    const brand = brands.find((brand) => brand.id === brandId);
    return brand ? brand.name : 'Unknown Brand';
  };

  return (
    <div className="phones">
      <h1>Phones</h1>
      <button className="create-phone-button" onClick={() => setIsPopupOpen(true)}>
        Create Phone
      </button>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h2>Create Phone</h2>
            <select
              value={selectedBrand}
              onChange={(e) => setSelectedBrand(e.target.value)}
              className="input-field"
            >
              <option value="" disabled>Select Brand</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
            {selectedBrand && (
              <select
                value={selectedSeries}
                onChange={(e) => setSelectedSeries(e.target.value)}
                className="input-field"
              >
                <option value="">No Series</option>
                {series.map((seriesItem) => (
                  <option key={seriesItem.id} value={seriesItem.name}>
                    {seriesItem.name}
                  </option>
                ))}
              </select>
            )}
            <input
              type="text"
              placeholder="Model Name"
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
              className="input-field"
            />
            <input
              type="file"
              onChange={(e) => setPhoneImage(e.target.files[0])}
              className="input-field"
            />
            <div className="popup-actions">
              <button className="btn" onClick={handleCreatePhone}>Create</button>
              <button className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {phones.length > 0 && (
        <table className="phones-table">
          <thead>
            <tr>
              <th>Brand Name</th>
              <th>Series</th>
              <th>Model Name</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {phones.map((phone) => (
              <tr key={phone.id}>
                <td>{getBrandNameById(phone.brand)}</td>
                <td>{phone.series || 'No Series'}</td>
                <td>{phone.model}</td>
                <td>
                  <img src={phone.imageUrl} alt={phone.model} className="phone-image" />
                </td>
                <td>
                  <button
                    className="btn btn-view-variants"
                    onClick={() => navigate(`/admin/phones/${phone.id}/variants`)}
                  >
                    View Variants
                  </button>
                  <button className="btn btn-edit">Edit</button>
                  <button
                    className="btn btn-delete"
                    onClick={() => handleDeletePhone(phone.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Phones;
