// src/components/Admin/Parts.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import './styles/Parts.css';

const Parts = () => {
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrands = async () => {
      const brandsCollectionRef = collection(db, 'brands');
      const data = await getDocs(brandsCollectionRef);
      setBrands(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchBrands();
  }, []);

  return (
    <div className="parts">
      <h1>Parts</h1>
      {brands.length > 0 ? (
        <table className="brands-table">
          <thead>
            <tr>
              <th>Brand Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand) => (
              <tr key={brand.id}>
                <td>{brand.name}</td>
                <td>
                  <button
                    className="btn btn-view"
                    onClick={() => navigate(`/admin/parts/${brand.id}/models`)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No brands available</p>
      )}
    </div>
  );
};

export default Parts;
