// src/components/Admin/BrandPhones.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import './styles/BrandPhones.css';
import BackButton from './BackButton';  // Import BackButton

const BrandPhones = () => {
  const { brandId } = useParams();
  const [phones, setPhones] = useState([]);
  const [brandName, setBrandName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (brandId) {
      const fetchPhones = async () => {
        const phonesCollectionRef = collection(db, 'phones');
        const q = query(phonesCollectionRef, where('brand', '==', brandId));
        const data = await getDocs(q);
        const phonesWithDetails = await Promise.all(
          data.docs.map(async (doc) => {
            const hasVariants = await checkForVariants(doc.id);
            const partsCount = hasVariants ? null : await getPartsCount(doc.id); // Only get parts count if no variants
            return { id: doc.id, hasVariants, partsCount, ...doc.data() };
          })
        );
        setPhones(phonesWithDetails);
      };

      const fetchBrandName = async () => {
        const brandDocRef = doc(db, 'brands', brandId);
        const brandDoc = await getDoc(brandDocRef);
        if (brandDoc.exists()) {
          setBrandName(brandDoc.data().name);
        } else {
          setBrandName('Unknown Brand');
        }
      };

      fetchPhones();
      fetchBrandName();
    }
  }, [brandId]);

  const checkForVariants = async (phoneId) => {
    const variantsCollectionRef = collection(db, `phones/${phoneId}/variants`);
    const variantsSnapshot = await getDocs(variantsCollectionRef);
    return variantsSnapshot.size > 0;
  };

  const getPartsCount = async (phoneId) => {
    const partsCollectionRef = collection(db, `phones/${phoneId}/parts`);
    const partsSnapshot = await getDocs(partsCollectionRef);
    return partsSnapshot.size;
  };

  return (
    <div className="brand-phones">
      <BackButton />  {/* Add BackButton here */}
      <h1>{brandName} Phones</h1>
      {phones.length > 0 ? (
        <table className="phones-table">
          <thead>
            <tr>
              <th>Model Name</th>
              <th>Series</th>
              <th>Image</th>
              {!phones.some(phone => phone.hasVariants) && <th>Parts Count</th>}  {/* Only show Parts Count column if no variants */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {phones.map((phone) => (
              <tr key={phone.id}>
                <td>{phone.model}</td>
                <td>{phone.series || 'No Series'}</td>
                <td>
                  <img src={phone.imageUrl} alt={phone.model} className="phone-image" />
                </td>
                {!phone.hasVariants && <td>{phone.partsCount}</td>}  {/* Only show Parts Count if no variants */}
                <td>
                  {phone.hasVariants ? (
                    <button
                      className="btn btn-view-variants"
                      onClick={() => navigate(`/admin/parts/${brandId}/phones/${phone.id}/variants`)}
                    >
                      View Variants
                    </button>
                  ) : (
                    <button
                      className="btn btn-parts"
                      onClick={() => navigate(`/admin/parts/${brandId}/phones/${phone.id}/parts`)}
                    >
                      Parts
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No phones available for this brand.</p>
      )}
    </div>
  );
};

export default BrandPhones;
