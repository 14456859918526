import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Admin/Sidebar';
import Brands from './components/Admin/Brands';
import Phones from './components/Admin/Phones';
import Series from './components/Admin/Series';
import Variants from './components/Admin/Variants';
import Parts from './components/Admin/Parts';
import BrandPhones from './components/Admin/BrandPhones';
import PartVariants from './components/Admin/PartVariants';
import PartsForModel from './components/Admin/PartsForModel';
import Users from './components/Admin/Users';
import Settings from './components/Admin/Settings';
import Home from './components/Home/Home';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin/*" element={<AdminLayout />} />
        </Routes>
      </div>
    </Router>
  );
}

const AdminLayout = () => (
  <div>
    <Sidebar />
    <Routes>
      <Route path="brands" element={<Brands />} />
      <Route path="phones" element={<Phones />} />
      <Route path="phones/:phoneId/variants" element={<Variants />} />
      <Route path="series" element={<Series />} />
      <Route path="parts" element={<Parts />} />
      <Route path="parts/:brandId/models" element={<BrandPhones />} />
      <Route path="parts/:brandId/phones/:phoneId/variants" element={<PartVariants />} />
      <Route path="parts/:brandId/phones/:phoneId/variants/:variantId/parts" element={<PartsForModel />} />
      <Route path="parts/:brandId/phones/:phoneId/parts" element={<PartsForModel />} />
      <Route path="users" element={<Users />} />
      <Route path="settings" element={<Settings />} />
    </Routes>
  </div>
);

export default App;
