// src/components/Admin/PartVariants.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore'; // Import doc and getDoc
import { db } from '../../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import './styles/PartVariants.css';
import BackButton from './BackButton';  // Import BackButton

const PartVariants = () => {
  const { brandId, phoneId } = useParams();
  const [variants, setVariants] = useState([]);
  const [phoneModelName, setPhoneModelName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVariants = async () => {
      const variantsCollectionRef = collection(db, `phones/${phoneId}/variants`);
      const data = await getDocs(variantsCollectionRef);
      const variantsWithPartsCount = await Promise.all(
        data.docs.map(async (doc) => {
          const partsCount = await getPartsCount(doc.id);
          return { id: doc.id, partsCount, ...doc.data() };
        })
      );
      setVariants(variantsWithPartsCount);
    };

    const fetchPhoneModelName = async () => {
      const phoneDocRef = doc(db, 'phones', phoneId);
      const phoneDoc = await getDoc(phoneDocRef);
      if (phoneDoc.exists()) {
        setPhoneModelName(phoneDoc.data().model);
      } else {
        setPhoneModelName('Unknown Model');
      }
    };

    fetchVariants();
    fetchPhoneModelName();
  }, [phoneId]);

  const getPartsCount = async (variantId) => {
    const partsCollectionRef = collection(db, `phones/${phoneId}/variants/${variantId}/parts`);
    const partsSnapshot = await getDocs(partsCollectionRef);
    return partsSnapshot.size;
  };

  return (
    <div className="part-variants">
      <BackButton />  {/* Add BackButton here */}
      <h1>Variants for {phoneModelName}</h1>
      {variants.length > 0 ? (
        <table className="variants-table">
          <thead>
            <tr>
              <th>Variant Name</th>
              <th>Model Number</th>
              <th>Image</th>
              <th>Parts Count</th> {/* Display the parts count */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {variants.map((variant) => (
              <tr key={variant.id}>
                <td>{variant.name}</td>
                <td>{variant.modelNumber}</td>
                <td>
                  <img src={variant.imageUrl} alt={variant.name} className="variant-image" />
                </td>
                <td>{variant.partsCount}</td> {/* Display the parts count */}
                <td>
                  <button
                    className="btn btn-parts"
                    onClick={() => navigate(`/admin/parts/${brandId}/phones/${phoneId}/variants/${variant.id}/parts`)}
                  >
                    Parts
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No variants available for this phone.</p>
      )}
    </div>
  );
};

export default PartVariants;
