  // src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import getStorage

const firebaseConfig = {
  apiKey: "AIzaSyDCxJXfI7P3GNn3q2_2Pf2dNcIrHd_jWns",
  authDomain: "k1movil.firebaseapp.com",
  projectId: "k1movil",
  storageBucket: "k1movil.appspot.com",
  messagingSenderId: "6582293386",
  appId: "1:6582293386:web:07b5ec2819a38c4d47ae73",
  measurementId: "G-H2C5T9MD4K"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Auth, and Storage
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize storage

// Export them for use in your components
export { db, auth, storage };
