// src/components/Admin/PartsForModel.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useParams } from 'react-router-dom';
import './styles/PartsForModel.css';
import BackButton from './BackButton';  // Import BackButton

const PartsForModel = () => {
  const { phoneId, variantId } = useParams();
  const [parts, setParts] = useState([]);
  const [partName, setPartName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [modelName, setModelName] = useState('');
  const [variantName, setVariantName] = useState('');

  const collectionPath = variantId
    ? `phones/${phoneId}/variants/${variantId}/parts`
    : `phones/${phoneId}/parts`;

  useEffect(() => {
    const fetchParts = async () => {
      const partsCollectionRef = collection(db, collectionPath);
      const data = await getDocs(partsCollectionRef);
      setParts(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    const fetchNames = async () => {
      const phoneDocRef = doc(db, 'phones', phoneId);
      const phoneDoc = await getDoc(phoneDocRef);
      if (phoneDoc.exists()) {
        setModelName(phoneDoc.data().model);
      }

      if (variantId) {
        const variantDocRef = doc(db, `phones/${phoneId}/variants`, variantId);
        const variantDoc = await getDoc(variantDocRef);
        if (variantDoc.exists()) {
          setVariantName(variantDoc.data().name);
        }
      }
    };

    fetchParts();
    fetchNames();
  }, [collectionPath, phoneId, variantId]);

  const handleCreatePart = async () => {
    if (!partName || !description || !price) return;

    const partsCollectionRef = collection(db, collectionPath);
    await addDoc(partsCollectionRef, {
      partName,
      description,
      price,
    });

    setPartName('');
    setDescription('');
    setPrice('');
    setIsPopupOpen(false);

    const data = await getDocs(partsCollectionRef);
    setParts(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  return (
    <div className="parts-for-model">
      <BackButton />  {/* Add BackButton here */}
      <h1>
        Parts for {variantId ? `${modelName} - ${variantName}` : `${modelName}`}
      </h1>
      <button className="btn create-part-button" onClick={() => setIsPopupOpen(true)}>
        Create Parts
      </button>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h2>Create Part</h2>
            <input
              type="text"
              placeholder="Part Name"
              value={partName}
              onChange={(e) => setPartName(e.target.value)}
              className="input-field"
            />
            <textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="input-field"
            />
            <input
              type="number"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="input-field"
            />
            <div className="popup-actions">
              <button className="btn" onClick={handleCreatePart}>
                Create
              </button>
              <button className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {parts.length > 0 ? (
        <table className="parts-table">
          <thead>
            <tr>
              <th>Part Name</th>
              <th>Description</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {parts.map((part) => (
              <tr key={part.id}>
                <td>{part.partName}</td>
                <td>{part.description}</td>
                <td>{part.price}</td>
                <td>
                  <button className="btn btn-edit">Edit</button>
                  <button className="btn btn-delete">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No parts available for this model.</p>
      )}
    </div>
  );
};

export default PartsForModel;
