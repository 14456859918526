// src/components/Admin/BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/BackButton.css';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button className="btn btn-back" onClick={() => navigate(-1)}>
      Back
    </button>
  );
};

export default BackButton;
