// src/components/Admin/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2 className="sidebar-title">Admin Panel</h2>
      <ul className="sidebar-menu">
        <li>
          <Link to="/admin/brands">Brands</Link>
        </li>
        <li>
          <Link to="/admin/series">Series</Link> 
        </li>
        <li>
          <Link to="/admin/phones">Phones</Link>
        </li>
        <li>
          <Link to="/admin/parts">Parts</Link>
        </li>
        <li>
          <Link to="/admin/users">Users</Link>
        </li>
        <li>
          <Link to="/admin/settings">Settings</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
