// src/components/Header.js
import React from 'react';
import './styles/Header.css'; // Import the header styles

const Header = () => {
  return (
    <header>
      {/* Top Bar */}
      <div className="top-bar">
        <div className="section repairs">528 repairs in progress today!</div>
        <div className="section guarantee">We bring your devices back to life in record time and with a guarantee!</div>
        <div className="section service">In the easiest way and without leaving home</div>
        <div className="section contact">
          <i className="fas fa-phone-alt"></i> 660 92 61 90
        </div>
      </div>

      {/* Main Header */}
      <div className="main-header">
        <div className="logo">
          <h1>K1movil</h1>
        </div>
        <nav className="main-menu">
          <ul>
            <li><a href="/">Menu Item 1</a></li>
            <li><a href="/">Menu Item 2</a></li>
            <li><a href="/">Menu Item 3</a></li>
          </ul>
        </nav>
        <div className="header-buttons">
          <button className="login-btn">
            <i className="fas fa-user"></i> Login
          </button>
          <button className="cart-btn">
            <i className="fas fa-shopping-bag"></i>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
