// src/components/Admin/Series.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase'; // Assuming you have Firebase setup
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './styles/Series.css';

const Series = () => {
  const [brands, setBrands] = useState([]);
  const [series, setSeries] = useState([]);
  const [seriesName, setSeriesName] = useState('');
  const [seriesImage, setSeriesImage] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingSeriesId, setEditingSeriesId] = useState(null);

  useEffect(() => {
    const fetchBrands = async () => {
      const brandsCollectionRef = collection(db, 'brands');
      const data = await getDocs(brandsCollectionRef);
      setBrands(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchSeries = async () => {
      if (selectedBrand) {
        const seriesCollectionRef = collection(db, `brands/${selectedBrand}/series`);
        const data = await getDocs(seriesCollectionRef);
        setSeries(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      }
    };

    fetchSeries();
  }, [selectedBrand]);

  const handleCreateOrUpdateSeries = async () => {
    if (!selectedBrand || !seriesName || !seriesImage) return;

    const imageRef = ref(storage, `series/${seriesImage.name}`);
    await uploadBytes(imageRef, seriesImage);
    const imageUrl = await getDownloadURL(imageRef);

    const seriesCollectionRef = collection(db, `brands/${selectedBrand}/series`);

    if (editingSeriesId) {
      const seriesDocRef = doc(db, `brands/${selectedBrand}/series`, editingSeriesId);
      await updateDoc(seriesDocRef, { name: seriesName, imageUrl });
    } else {
      await addDoc(seriesCollectionRef, {
        name: seriesName,
        imageUrl,
      });
    }

    setSeriesName('');
    setSeriesImage(null);
    setEditingSeriesId(null);
    setIsPopupOpen(false);

    const data = await getDocs(seriesCollectionRef);
    setSeries(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const handleDeleteSeries = async (id) => {
    const seriesDocRef = doc(db, `brands/${selectedBrand}/series`, id);
    await deleteDoc(seriesDocRef);

    setSeries(series.filter((series) => series.id !== id));
  };

  const openEditPopup = (series) => {
    setSeriesName(series.name);
    setSelectedBrand(selectedBrand);
    setEditingSeriesId(series.id);
    setIsPopupOpen(true);
  };

  return (
    <div className="series">
      <h1>Series</h1>
      <select
        value={selectedBrand}
        onChange={(e) => setSelectedBrand(e.target.value)}
        className="input-field"
      >
        <option value="" disabled>Select Brand</option>
        {brands.map((brand) => (
          <option key={brand.id} value={brand.id}>
            {brand.name}
          </option>
        ))}
      </select>
      <button className="create-series-button" onClick={() => setIsPopupOpen(true)}>
        Create Series
      </button>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h2>{editingSeriesId ? 'Edit Series' : 'Create Series'}</h2>
            <input
              type="text"
              placeholder="Series Name"
              value={seriesName}
              onChange={(e) => setSeriesName(e.target.value)}
              className="input-field"
            />
            <input
              type="file"
              onChange={(e) => setSeriesImage(e.target.files[0])}
              className="input-field"
            />
            <div className="popup-actions">
              <button className="btn" onClick={handleCreateOrUpdateSeries}>
                {editingSeriesId ? 'Update' : 'Create'}
              </button>
              <button className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {series.length > 0 && (
        <table className="series-table">
          <thead>
            <tr>
              <th>Series Name</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {series.map((series) => (
              <tr key={series.id}>
                <td>{series.name}</td>
                <td>
                  <img src={series.imageUrl} alt={series.name} className="series-image" />
                </td>
                <td>
                  <button className="btn btn-edit" onClick={() => openEditPopup(series)}>Edit</button>
                  <button className="btn btn-delete" onClick={() => handleDeleteSeries(series.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Series;
