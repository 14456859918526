// src/components/Admin/Variants.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import './styles/Variants.css';

const Variants = () => {
  const { phoneId } = useParams();
  const [variants, setVariants] = useState([]);
  const [variantName, setVariantName] = useState('');
  const [modelNumber, setModelNumber] = useState('');
  const [variantImage, setVariantImage] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchVariants = async () => {
      const variantsCollectionRef = collection(db, `phones/${phoneId}/variants`);
      const data = await getDocs(variantsCollectionRef);
      setVariants(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchVariants();
  }, [phoneId]);

  const handleCreateVariant = async () => {
    if (!variantName || !modelNumber || !variantImage) return;

    const imageRef = ref(storage, `variants/${variantImage.name}`);
    await uploadBytes(imageRef, variantImage);
    const imageUrl = await getDownloadURL(imageRef);

    const variantsCollectionRef = collection(db, `phones/${phoneId}/variants`);
    await addDoc(variantsCollectionRef, {
      name: variantName,
      modelNumber,
      imageUrl,
    });

    const data = await getDocs(variantsCollectionRef);
    setVariants(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

    setVariantName('');
    setModelNumber('');
    setVariantImage(null);
    setIsPopupOpen(false);
  };

  const handleDeleteVariant = async (id) => {
    await deleteDoc(doc(db, `phones/${phoneId}/variants`, id));
    setVariants(variants.filter((variant) => variant.id !== id));
  };

  return (
    <div className="variants">
      <h1>Variants</h1>
      <button className="create-variant-button" onClick={() => setIsPopupOpen(true)}>
        Create Variant
      </button>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h2>Create Variant</h2>
            <input
              type="text"
              placeholder="Variant Name"
              value={variantName}
              onChange={(e) => setVariantName(e.target.value)}
              className="input-field"
            />
            <input
              type="text"
              placeholder="Model Number"
              value={modelNumber}
              onChange={(e) => setModelNumber(e.target.value)}
              className="input-field"
            />
            <input
              type="file"
              onChange={(e) => setVariantImage(e.target.files[0])}
              className="input-field"
            />
            <div className="popup-actions">
              <button className="btn" onClick={handleCreateVariant}>Create</button>
              <button className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {variants.length > 0 && (
        <table className="variants-table">
          <thead>
            <tr>
              <th>Variant Name</th>
              <th>Model Number</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {variants.map((variant) => (
              <tr key={variant.id}>
                <td>{variant.name}</td>
                <td>{variant.modelNumber}</td>
                <td>
                  <img src={variant.imageUrl} alt={variant.name} className="variant-image" />
                </td>
                <td>
                  <button className="btn btn-edit">Edit</button>
                  <button
                    className="btn btn-delete"
                    onClick={() => handleDeleteVariant(variant.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Variants;

