// src/components/Admin/Users.js
import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './styles/Users.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('User');
  const [isEditing, setIsEditing] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollectionRef = collection(db, 'users');
      const data = await getDocs(usersCollectionRef);
      setUsers(data.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchUsers();
  }, []);

  const handleCreateUser = async () => {
    if (!name || !email || !password) return;

    const auth = getAuth();

    try {
        // Create the user in Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Add user data to Firestore
        const usersCollectionRef = collection(db, 'users');
        await addDoc(usersCollectionRef, {
            uid: user.uid, // Store the UID from Firebase Authentication
            name,
            email,
            role,
        });

        setName('');
        setEmail('');
        setPassword('');
        setRole('User');
        setIsPopupOpen(false);

        // Fetch updated users from Firestore
        const data = await getDocs(usersCollectionRef);
        setUsers(data.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
        console.error("Error creating user: ", error);
    }
};

  const handleEditUser = (user) => {
    setIsEditing(true);
    setCurrentUserId(user.id);
    setName(user.name);
    setEmail(user.email);
    setPassword(user.password);
    setRole(user.role);
    setIsPopupOpen(true);
  };

  const handleUpdateUser = async () => {
    if (!name || !email || !password) return;

    const userDocRef = doc(db, 'users', currentUserId);
    await updateDoc(userDocRef, {
      name,
      email,
      password,
      role,
    });

    setIsEditing(false);
    setCurrentUserId(null);
    setName('');
    setEmail('');
    setPassword('');
    setRole('User');
    setIsPopupOpen(false);

    const usersCollectionRef = collection(db, 'users');
    const data = await getDocs(usersCollectionRef);
    setUsers(data.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleDeleteUser = async (userId) => {
    const userDocRef = doc(db, 'users', userId);
    await deleteDoc(userDocRef);

    const usersCollectionRef = collection(db, 'users');
    const data = await getDocs(usersCollectionRef);
    setUsers(data.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleRoleChange = async (userId, newRole) => {
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, {
      role: newRole,
    });

    const usersCollectionRef = collection(db, 'users');
    const data = await getDocs(usersCollectionRef);
    setUsers(data.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  return (
    <div className="users">
     
      <h1>Users</h1>
      <button className="btn create-user-button" onClick={() => setIsPopupOpen(true)}>
        Create User
      </button>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h2>{isEditing ? 'Edit User' : 'Create User'}</h2>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-field"
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
            />
            <select value={role} onChange={(e) => setRole(e.target.value)} className="input-field">
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </select>
            <div className="popup-actions">
              <button className="btn" onClick={isEditing ? handleUpdateUser : handleCreateUser}>
                {isEditing ? 'Update' : 'Create'}
              </button>
              <button className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {users.length > 0 ? (
        <table className="users-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  <select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    className="input-field"
                  >
                    <option value="User">User</option>
                    <option value="Admin">Admin</option>
                  </select>
                </td>
                <td>
                  <button className="btn btn-edit" onClick={() => handleEditUser(user)}>
                    Edit
                  </button>
                  <button className="btn btn-delete" onClick={() => handleDeleteUser(user.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No users available.</p>
      )}
    </div>
  );
};

export default Users;
